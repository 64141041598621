<template>
    <div class="setting_wpr">
        <div v-if="assetsLoader">
            <p class="text-center">Please wait... as we are loading your assets</p>
        </div>
        <Form @submit="handleSubmit" v-slot="{ errors }" v-else>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Playbooks</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.playbook }">
                        <Field autocomplete="off" name="playbook" v-model="form.playbooks" :class="{ 'has-error': errors.playbook }">
                            <multiselect
                                v-model="form.playbooks"
                                :options="selectedPlaybook"
                                value-prop="id"
                                label="name"
                                :searchable="true"
                                placeholder="Select playbooks"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="playbook" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Forms</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.form }">
                        <Field autocomplete="off" name="form" v-model="form.forms" :class="{ 'has-error': errors.form }">
                            <multiselect
                                v-model="form.forms"
                                :options="selectedForm"
                                value-prop="id"
                                label="survey_name"
                                :searchable="true"
                                placeholder="Select forms"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="form" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Pages</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.page }">
                        <Field autocomplete="off" name="page" v-model="form.pages" :class="{ 'has-error': errors.page }">
                            <multiselect
                                v-model="form.pages"
                                :options="selectedPage"
                                value-prop="id"
                                label="title"
                                :searchable="true"
                                placeholder="Select pages"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="page" class="text-danger" />
                </div>
            </div>
            <!-- <div class="form_grp" v-show="1 == 2">
                <div class="group_item">
                    <label class="input_label">Active Pipelines</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.pipeline }">
                        <Field autocomplete="off" name="pipeline" v-model="form.pipelines" :class="{ 'has-error': errors.pipeline }">
                            <multiselect
                                v-model="form.pipelines"
                                :options="pipelines"
                                value-prop="id"
                                label="title"
                                :searchable="true"
                                placeholder="Select pipelines"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="pipeline" class="text-danger" />
                </div>
            </div> -->
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Journeys <a class="option" @click="handleRefreshList()">Refresh List</a></label>
                    <div class="field_wpr" :class="{ 'has-error': errors.journey }">
                        <Field autocomplete="off" name="journey" v-model="form.journeys" :class="{ 'has-error': errors.journey }">
                            <multiselect
                                v-model="form.journeys"
                                :options="selectedJourney"
                                value-prop="id"
                                label="name"
                                :searchable="true"
                                placeholder="Select journeys"
                                mode="tags"
                            >
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag" :class="{ 'grey-tags': option.is_delete == 1 }">
                                      {{ option.name }}
                                      <span
                                        v-if="!disabled"
                                        class="multiselect-tag-remove"
                                        @mousedown.prevent="handleTagRemove(option, $event)"
                                      >
                                          <span class="multiselect-tag-remove-icon"></span>
                                      </span>
                                    </div>
                                </template>
                            </multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="journey" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Sequences</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.sequence }">
                        <Field autocomplete="off" name="sequence" v-model="form.sequences" :class="{ 'has-error': errors.sequence }">
                            <multiselect
                                v-model="form.sequences"
                                :options="sequences"
                                value-prop="id"
                                label="name"
                                :searchable="true"
                                placeholder="Select sequences"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="sequence" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Workouts</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.workout }">
                        <Field autocomplete="off" name="workout" v-model="form.workouts" :class="{ 'has-error': errors.workout }">
                            <multiselect
                                v-model="form.workouts"
                                :options="workouts"
                                value-prop="id"
                                label="name"
                                :searchable="true"
                                placeholder="Select workouts"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="workout" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Workout Plan</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.workout_plan }">
                        <Field autocomplete="off" name="workout_plan" v-model="form.workout_plans"  label="workout plan">
                            <multiselect
                                v-model="form.workout_plans"
                                label="name"
                                value-prop="id"
                                :searchable="true"
                                :options="workoutPlans"
                                placeholder="Select Workout Plan"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="workout_plan" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Client Tracking</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.progress_tracking }">
                        <Field autocomplete="off" name="progress_tracking" v-model="form.progress_trackings" :class="{ 'has-error': errors.progress_tracking }">
                            <multiselect
                                v-model="form.progress_trackings"
                                :options="progressTrackings"
                                value-prop="id"
                                label="name"
                                :searchable="true"
                                placeholder="Select client trackings"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="progress_tracking" class="text-danger" />
                </div>
            </div>
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Habit Scenarios</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.habit_tracking }">
                        <Field autocomplete="off" name="habit_tracking" v-model="form.habit_trackings" :class="{ 'has-error': errors.habit_tracking }">
                            <multiselect
                                v-model="form.habit_trackings"
                                :options="habitTrackings"
                                value-prop="id"
                                label="name"
                                :searchable="true"
                                placeholder="Select habit trackings"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="habit_tracking" class="text-danger" />
                </div>
            </div>
            <!-- <div class="form_grp" v-show="1 == 2">
                <div class="group_item">
                    <label class="input_label">Active Tasks</label>
                    <div class="field_wpr" :class="{ 'has-error': errors.task }">
                        <Field autocomplete="off" name="task" v-model="form.tasks" :class="{ 'has-error': errors.task }">
                            <multiselect
                                v-model="form.tasks"
                                :options="tasks"
                                value-prop="id"
                                label="name"
                                :searchable="true"
                                placeholder="Select tasks"
                                mode="tags"
                            ></multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="task" class="text-danger" />
                </div>
            </div> -->
            <div class="form_grp">
                <div class="group_item">
                    <label class="input_label">Active Tags <a class="option" @click="createTags = !createTags">Create a Tag</a></label>
                    <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                        <Field autocomplete="off" name="tag" v-model="form.tags" :class="{ 'has-error': errors.tag }">
                            <multiselect
                                v-model="form.tags"
                                :groups="true"
                                :searchable="true"
                                :options="tagsGroup"
                                value-prop="id"
                                label="name"
                                placeholder="Select tag"
                                :group-select="false"
                                group-label="type"
                                group-options="tags"
                                :group-hide-empty="true"
                                mode="tags"
                            >
                                <template v-slot:grouplabel="{ group }">
                                    <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span>{{ option.name }}</span>
                                </template>
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                      {{ option.name }}
                                      <span
                                        v-if="!disabled"
                                        class="multiselect-tag-remove"
                                        @mousedown.prevent="handleTagRemove(option, $event)"
                                      >
                                          <span class="multiselect-tag-remove-icon"></span>
                                      </span>
                                    </div>
                                </template>
                            </multiselect>
                        </Field>
                    </div>
                    <ErrorMessage name="tag" class="text-danger" />
                </div>
            </div>

            <div v-if="createTags" class="form_grp">
                <Form v-slot="{ errors }" ref="lauchpad-tag-form">
                    <div class="group_item">
                        <label class="input_label">Create a New Tag</label>
                        <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                            <Field autocomplete="off" v-model="tagForm.name" name="name" type="text" placeholder="Enter Tag Name.." rules="required" />
                            <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                        </div>
                        <ErrorMessage name="name" class="text-danger" />
                    </div>
                </Form>
            </div>

            <div class="action_wpr mt-4">
                <button :disabled="contactLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="contactLoader"></i> {{ contactLoader ? 'Saving' : 'Save' }}</button>
            </div>
        </Form>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Launchpad Advance',

        data () {
            return  {
                form: {
                    contact_id: 0,
                    playbooks: [],
                    forms: [],
                    pages: [],
                    pipelines: [],
                    journeys: [],
                    sequences: [],
                    progress_trackings: [],
                    habit_trackings: [],
                    tasks: [],
                    tags: [],
                    workouts: [],
                    workout_plans:[],
                },
                createTags: false,
                tagForm: {
                    name: '',
                },
                assetsLoaded: false,
            };
        },

        props: {
            contact: Object,
            tabWatcher: Number,
            closeLaunchPad: Function,
        },

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            contact () {
                const vm = this;
                vm.resetForm();
                vm.filterSelected();
            },

            tabWatcher () {
                const vm = this;

                vm.resetForm();
                vm.filterSelected();
            },

            'form.forms' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'forms' : 'form';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name} ?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.forms = oldVal;
                        }
                    });
                }

            },

            'form.playbooks' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'playbooks' : 'playbook';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.playbooks = oldVal;
                        }
                    });
                }
            },

            'form.pages' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'pages' : 'page';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.pages = oldVal;
                        }
                    });
                }
            },

            'form.journeys' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'journeys' : 'journey';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.journeys = oldVal;
                        }
                    });
                }
            },

            'form.sequences' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'sequences' : 'sequence';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.sequences = oldVal;
                        }
                    });
                }
            },

            'form.pipelines' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'pipelines' : 'pipeline';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.pipelines = oldVal;
                        }
                    });
                }
            },

            'form.tasks' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'tasks' : 'task';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.tasks = oldVal;
                        }
                    });
                }
            },

            'form.tags' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'tags' : 'tag';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.tags = oldVal;
                        }
                    });
                }
            },

            'form.progress_trackings' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'progress scenarios' : 'progress scenario';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} form for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.progress_trackings = oldVal;
                        }
                    });
                }
            },

            'form.habit_trackings' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'habit scenarios' : 'habit scenario';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.habit_trackings = oldVal;
                        }
                    });
                }
            },

            'form.workouts' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'workouts' : 'workout';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.workouts = oldVal;
                        }
                    });
                }
            },

            'form.workout_plans' (newVal, oldVal) {
                const vm = this;

                if ((newVal.length < oldVal.length) && !vm.assetsLoader && vm.assetsLoaded) {
                    const text   = oldVal.length - newVal.length > 1 ? 'workout plans' : 'workout plan';
                    const option = Helper.swalConfirmOptions('Are you sure?', `You want to remove ${text} for ${vm.contact.name}?`, 'Yes', false);

                    Swal.fire(option).then((result) => {
                        if (!result.isConfirmed) {
                            vm.form.workout_plans = oldVal;
                        }
                    });
                }
            },

            contactAssetsWatcher (val) {
                const vm = this;

                vm.resetForm();
                vm.filterSelected();
            },

            assetsLoader (loader) {
                const vm = this;

                vm.assetsLoaded = false;

                if (loader || vm.contactLoader) {
                    vm.assetsLoaded = false;
                } else {
                    setTimeout(() => {
                        vm.assetsLoaded = true;
                    }, 1000)
                }
            },

            contactLoader (loader) {
                const vm = this;

                vm.assetsLoaded = false;

                if (loader || vm.assetsLoader) {
                    vm.assetsLoaded = false;
                } else {
                    setTimeout(() => {
                        vm.assetsLoaded = true;
                    }, 1000)
                }
            },

            contactsRefreshLoader (loader) {
                const vm = this;

                vm.assetsLoaded = false;

                if (loader || vm.assetsLoader || vm.contactLoader) {
                    vm.assetsLoaded = false;
                } else {
                    setTimeout(() => {
                        vm.assetsLoaded = true;
                    }, 1000)
                }
            },
        },

        computed: mapState({
            contactLoader: state => state.contactModule.contactSaveLoader,
            selectedJourney: state => state.contactModule.journeyList,
            selectedPlaybook: state => state.contactModule.playbookList,
            selectedSequence: state => state.contactModule.selectedSequence,
            selectedPipeline: state => state.contactModule.selectedPipelineList,
            selectedForm: state => state.contactModule.selectedFormList,
            selectedPage: state => state.contactModule.selectedPageList,
            selectedProgressTracking: state => state.contactModule.selectedProgressTracking,
            selectedTask: state => state.contactModule.selectedTask,
            selectedHabitTracking: state => state.contactModule.selectedHabitTracking,
            selectedWorkout: state => state.contactModule.selectedWorkout,
            selectedWorkoutPlans: state => state.contactModule.selectedWorkoutPlans,
            selectedTags: state => state.contactModule.selectedTags,
            playbooks: state => state.playbookModule.allPlaybooks,
            pages: state => state.pageModule.allPages,
            forms: state => state.formModule.allForms,
            journeys: state => state.journeyModule.allJourneys,
            tagsGroup: state => state.tagModule.tagsGroup,
            pipelines: state => state.pipelineModule.allPipelines,
            sequences: state => state.sequenceModule.allSequences,
            progressTrackings: state => state.progressTrackingModule.allProgressTrackings,
            habitTrackings: state => state.habitTrackingModule.allHabitTrackings,
            tasks: state => state.taskModule.allTasks,
            workouts: state => state.workoutModule.allWorkouts,
            workoutPlans: state => state.workoutModule.allWorkoutPlans,

            tagLoader: state => state.tagModule.tagLoader,
            assetsLoader: state => state.contactModule.assetsLoader,
            contactAssetsWatcher: state => state.contactModule.contactAssetsWatcher,
            contactsRefreshLoader: state => state.contactModule.contactsLoader,
        }),

        mounted () {
            const vm = this;

            vm.form.contact_id = vm.contact.id;

            setTimeout(function () {
                if (vm.tagsGroup.length == 0) {
                    vm.getTags();
                }

                if (vm.pipelines.length == 0) {
                    vm.getAllPipelines({ select: 'id,name' });
                }

                if (vm.sequences.length == 0) {
                    vm.getAllSequences({ select: 'id,name' });
                }

                if (vm.progressTrackings.length == 0) {
                    vm.getAllProgressTrackings({ select: 'id,name' });
                }

                if (vm.habitTrackings.length == 0) {
                    vm.getAllHabitTrackings({ select: 'id,name' });
                }

                if (vm.tasks.length == 0) {
                    vm.getAllTasks({ select: 'id,name' });
                }

                if (vm.workouts.length == 0) {
                    vm.getAllWorkouts({ select: 'id,name' });
                }

                if (vm.workoutPlans.length == 0) {
                    vm.getAllWorkoutPlans({ select: 'id,name' });
                }
            }, 1500);

            vm.resetForm();
            vm.filterSelected();
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
                getAllPipelines: 'pipelineModule/getAllPipelines',
                getAllSequences: 'sequenceModule/getAllSequences',
                getAllProgressTrackings: 'progressTrackingModule/getAllProgressTrackings',
                getAllHabitTrackings: 'habitTrackingModule/getAllHabitTrackings',
                getAllTasks: 'taskModule/getAllTasks',
                getAllWorkouts: 'workoutModule/getAllWorkouts',
                getAllWorkoutPlans: 'workoutModule/getAllWorkoutPlans',
                updateFromLaunchPad: 'contactModule/updateFromLaunchPad',
                getAssets: 'contactModule/getAssets',
            }),

            filterSelected () {
                const vm = this;

                setTimeout(function () {
                    const journeys          = JSON.parse(JSON.stringify(vm.selectedJourney));
                    const playbooks         = JSON.parse(JSON.stringify(vm.selectedPlaybook));
                    const sequences         = JSON.parse(JSON.stringify(vm.selectedSequence));
                    const pipelines         = JSON.parse(JSON.stringify(vm.selectedPipeline));
                    const forms             = JSON.parse(JSON.stringify(vm.selectedForm));
                    const pages             = JSON.parse(JSON.stringify(vm.selectedPage));
                    const progressTrackings = JSON.parse(JSON.stringify(vm.selectedProgressTracking));
                    const habitTrackings    = JSON.parse(JSON.stringify(vm.selectedHabitTracking));
                    const tasks             = JSON.parse(JSON.stringify(vm.selectedTask));
                    const workouts          = JSON.parse(JSON.stringify(vm.selectedWorkout));
                    const workoutPlans      = JSON.parse(JSON.stringify(vm.selectedWorkoutPlans));
                    const tags              = JSON.parse(JSON.stringify(vm.selectedTags));

                    vm.form.journeys            = journeys.map(journey => journey.id);
                    vm.form.playbooks           = playbooks.map(playbook => playbook.id);
                    vm.form.sequences           = sequences.map(sequence => sequence.id);
                    vm.form.pipelines           = pipelines.map(pipeline => pipeline.id);
                    vm.form.forms               = forms.map(form => form.id);
                    vm.form.pages               = pages.map(page => page.id);
                    vm.form.progress_trackings  = progressTrackings.map(progressTracking => progressTracking.id);
                    vm.form.habit_trackings     = habitTrackings.map(habitTracking => habitTracking.id);
                    vm.form.tasks               = tasks.map(task => task.id);
                    vm.form.workouts            = workouts.map(workout => workout.id);
                    vm.form.workout_plans       = workoutPlans.map(plan => plan.id);
                    vm.form.tags                = tags.map(tags => tags.id);
                }, 100);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                              contact_id: vm.contact.id,
                              playbooks: [],
                              forms: [],
                              pages: [],
                              pipelines: [],
                              journeys: [],
                              sequences: [],
                              progress_trackings: [],
                              habit_trackings: [],
                              tasks: [],
                              tags: [],
                              workouts: [],
                              workout_plans:[],
                          };
            },

            handleSubmit (params, { setFieldError }) {
                const vm = this;
                vm.form.contact_id    = vm.contact.id;
                vm.form.setFieldError = setFieldError;

                vm.updateFromLaunchPad(vm.form).then((result) => {
                    if (result && vm.closeLaunchPad) {
                        vm.closeLaunchPad();
                    }
                });
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['lauchpad-tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            if (result) {
                                vm.createTags = false;
                                vm.tagForm.name = '';
                                vm.getTags();
                            }
                        });
                    }
                });
            },

            handleRefreshList () {
                const vm = this;

                vm.getAssets({ contact_id: vm.contact.id, isRefreshList: true });
            }
        },
    }
</script>
